.tanora-popup {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.createHomework {
    width: 100%;
    padding: 3px;
    margin-top: 5px;

}

.diakok-nev {
    padding: 5px;
    width: 40%;
    line-height: 1.2rem;
    font-size: large;

}

.diakok-jelenlet {
    padding: 5px;
    width: 60%;
}

.attNone {
    padding: 3px;
    background-color: #f3edec;
}

.attGreen {
    padding: 3px;
    background-color: #7dc78d;
}