.reg-box {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    cursor: pointer;
}