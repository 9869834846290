#scheduler {
  height: 600px;
}


.dx-scheduler-date-table-cell {
  height: 18px !important;
}

.dx-scheduler-time-panel-cell {
  height: 18px !important;
}

#scheduler .fa-calendar-times {
  color: red;
}

.green {
  color: green;
}

.red {
  color: darkred;
}

.dx-scheduler-appointment {
  box-sizing: content-box;
  padding-right: 5px;
}
.dx-scheduler-timeline .dx-scheduler-appointment,
.dx-scheduler-work-space-month .dx-scheduler-appointment,
.dx-scheduler-all-day-appointment .dx-scheduler-appointment {
  box-sizing: border-box;
  padding-right: 0;
}
 
.tanora-tooltip .tanora-info {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  text-align: left;
}

.tanora-tooltip .tanora-title {
  font-size: 1.5em;
  line-height: 60px;
}

.dx-scheduler .dx-item-content {
  font-size: x-small !important;
}

.dx-scheduler-time-panel {
  font-size: xx-small !important;
}
.dx-scheduler-header-panel {
  font-size: x-small;
}
