.w3-theme-indigo-l5 {color:#000 !important; background-color:#f3f4fb !important}
.w3-theme-indigo-l4 {color:#000 !important; background-color:#d8dcf1 !important}
.w3-theme-indigo-l3 {color:#000 !important; background-color:#b1b8e3 !important}
.w3-theme-indigo-l2 {color:#fff !important; background-color:#8995d6 !important}
.w3-theme-indigo-l1 {color:#fff !important; background-color:#6271c8 !important}
.w3-theme-indigo-d1 {color:#fff !important; background-color:#3949a3 !important}
.w3-theme-indigo-d2 {color:#fff !important; background-color:#334191 !important}
.w3-theme-indigo-d3 {color:#fff !important; background-color:#2d397f !important}
.w3-theme-indigo-d4 {color:#fff !important; background-color:#26316d !important}
.w3-theme-indigo-d5 {color:#fff !important; background-color:#20295b !important}

.w3-theme-indigo-light {color:#000 !important; background-color:#f3f4fb !important}
.w3-theme-indigo-dark {color:#fff !important; background-color:#20295b !important}
.w3-theme-indigo-action {color:#fff !important; background-color:#20295b !important}

.w3-theme-indigo {color:#fff !important; background-color:#3f51b5 !important}
.w3-text-indigo-theme {color:#3f51b5 !important}
.w3-border-indigo-theme {border-color:#3f51b5 !important}

.w3-hover-indigo-theme:hover {color:#fff !important; background-color:#3f51b5 !important}
.w3-hover-indigo-text-theme:hover {color:#3f51b5 !important}
.w3-hover-indigo-border-theme:hover {border-color:#3f51b5 !important}