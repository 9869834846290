.hw-legend {
    margin-bottom: 10px;
}

.hw-filter {
    margin-bottom: 10px;
    display: flex
}

.hw-header-box {
    display: flex;
    justify-content: space-between;
    align-items: top;
    margin-bottom: 10px;
}

.homerwork-create {
    background-color: #73bd9eb4;
}

.hw-create-new {
    width: 40%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
}

.homework-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.homework-items {
    width: 100%;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    cursor: pointer;
}

.homework-status {
    width: 9%;

}

.homework-button {
    margin-top: 10px;
    width: 100%;
    font-size: xx-large;
}

.hw-replied {
    color: #1cb617;
}

.hw-button-text {
    font-size: smaller;
}

.hw-error {
    color: #c54613;
}

.hw-reply-items {
    display: block;
}