.homework-item {
  width: 90%;
  margin: 5px;
  padding: 5px;

}

.hw-column {
  float: left;

}

.hw-column.main {
  width: 66.66%;
}

.hw-column.file {
  width: 33.33%;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 700px) {

  .hw-column.main,
  .hw-column.file {
    width: 100%;
  }
}