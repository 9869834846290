.w3-theme-dark-orange-l5 {
  color: #000 !important;
  background-color: #fff5f2 !important;
}
.w3-theme-dark-orange-l4 {
  color: #000 !important;
  background-color: #ffddd3 !important;
}
.w3-theme-dark-orange-l3 {
  color: #000 !important;
  background-color: #ffbca7 !important;
}
.w3-theme-dark-orange-l2 {
  color: #000 !important;
  background-color: #ff9a7b !important;
}
.w3-theme-dark-orange-l1 {
  color: #fff !important;
  background-color: #ff7850 !important;
}
.w3-theme-dark-orange-d1 {
  color: #fff !important;
  background-color: #ff4107 !important;
}
.w3-theme-dark-orange-d2 {
  color: #fff !important;
  background-color: #e93600 !important;
}
.w3-theme-dark-orange-d3 {
  color: #fff !important;
  background-color: #cb2f00 !important;
}
.w3-theme-dark-orange-d4 {
  color: #fff !important;
  background-color: #ae2900 !important;
}
.w3-theme-dark-orange-d5 {
  color: #fff !important;
  background-color: #912200 !important;
}

.w3-theme-dark-orange-light {
  color: #000 !important;
  background-color: #fff5f2 !important;
}
.w3-theme-dark-orange-dark {
  color: #fff !important;
  background-color: #912200 !important;
}
.w3-theme-dark-orange-action {
  color: #fff !important;
  background-color: #912200 !important;
}

.w3-theme-dark-orange {
  color: #fff !important;
  background-color: #ff5722 !important;
}
.w3-text-theme-dark-orange {
  color: #ff5722 !important;
}
.w3-border-theme-dark-orange {
  border-color: #ff5722 !important;
}

.w3-hover-theme-dark-orange:hover {
  color: #fff !important;
  background-color: #ff5722 !important;
}
.w3-hover-text-theme-dark-orange:hover {
  color: #ff5722 !important;
}
.w3-hover-border-theme-dark-orange:hover {
  border-color: #ff5722 !important;
}
